<template>

  <section id="content" class="pt-12 mb-md-12 mb-lg-12">
  	<v-row class="mx-0" v-if="$vuetify.breakpoint.smAndUp">
  	 	<v-col cols="12" class="pa-0">
        <v-img
          :src="require('@/assets/Home/madera.jpg')"
          class="mx-auto"
          style="position: absolute;">
          <div class="white" style="width: 100%; z-index: -1; position: absolute; top: 0; left: 0;" :style="line"></div>
  	  	 	<v-img
  		      :src="require('@/assets/Home/plato.png')"
  		      width="30%"
            height="auto"
            class="mx-auto"
  		    ></v-img> 
        </v-img>
      <v-container class="px-4" :class="{'mt-content': $vuetify.breakpoint.mdAndUp, 'py-12': $vuetify.breakpoint.mdAndUp, 'py-9': $vuetify.breakpoint.xsOnly, 'py-12': $vuetify.breakpoint.smOnly }">
      <v-responsive height="100%" width="100%">
        <v-row class="mx-0" :class="{'mt-12': $vuetify.breakpoint.mdAndUp, 'pt-12': $vuetify.breakpoint.mdAndUp, 'py-0': $vuetify.breakpoint.smAndDown }">
          <v-col cols="4" sm="3" md="3" lg="3" offset-sm="1" offset-md="1" offset-lg="1" class="text-center" :class="{ 'pb-0': $vuetify.breakpoint.smAndDown, 'pt-0': $vuetify.breakpoint.xsOnly }">

            <span class="title-content" :class="{'lg-view' : $vuetify.breakpoint.mdAndUp, 'sm-view': $vuetify.breakpoint.smOnly, 'xl-view': $vuetify.breakpoint.xlOnly, 'xs-view': $vuetify.breakpoint.xsOnly,}">Imágenes</span>

            <p class="description-content pt-sm-1 pt-md-3 pt-lg-4" :class="{'lg-view' : $vuetify.breakpoint.mdAndUp, 'sm-view': $vuetify.breakpoint.smOnly, 'xl-view': $vuetify.breakpoint.xlOnly, 'xs-view': $vuetify.breakpoint.xsOnly,}">Capta la atención de los clientes de forma que le supongan una motivación para pedir.</p>
          </v-col>

          <v-col cols="4" sm="3" md="3" lg="3" offset="4" class="text-center" :class="{ 'pb-0': $vuetify.breakpoint.smAndDown, 'pt-0': $vuetify.breakpoint.xsOnly }">
            <span class="title-content" :class="{'lg-view' : $vuetify.breakpoint.mdAndUp, 'sm-view': $vuetify.breakpoint.smOnly, 'xl-view': $vuetify.breakpoint.xlOnly, 'xs-view': $vuetify.breakpoint.xsOnly,}">Descripción</span>

            <p class="description-content pt-sm-1 pt-md-3 pt-lg-4" :class="{'lg-view' : $vuetify.breakpoint.mdAndUp, 'sm-view': $vuetify.breakpoint.smOnly, 'xl-view': $vuetify.breakpoint.xlOnly, 'xs-view': $vuetify.breakpoint.xsOnly,}">Describe tu plato para dar más información.</p>
          </v-col>
        </v-row>  
        <v-row class="mx-0 mb-xl-12" :class="{'mt-12': $vuetify.breakpoint.mdAndUp, 'pt-0': $vuetify.breakpoint.smAndDown }">
          <v-col cols="4" sm="3" md="3" lg="3" offset-sm="1" offset-md="1" offset-lg="1" class="text-center" :class="{ 'pt-0': $vuetify.breakpoint.smAndDown }">
            <span class="title-content" :class="{'lg-view' : $vuetify.breakpoint.mdAndUp, 'sm-view': $vuetify.breakpoint.smOnly, 'xl-view': $vuetify.breakpoint.xlOnly, 'xs-view': $vuetify.breakpoint.xsOnly,}">Alérgenos</span>

            <p class="description-content pt-sm-1 pt-md-3 pt-lg-4 px-md-6 px-lg-6" :class="{'lg-view' : $vuetify.breakpoint.mdAndUp, 'sm-view': $vuetify.breakpoint.smOnly, 'xl-view': $vuetify.breakpoint.xlOnly, 'xs-view': $vuetify.breakpoint.xsOnly,}">Pon los alérgenos de cada plato y tus clientes filtrarán los platos para ir a lo que puedan comer.</p>
          </v-col>
          <v-col cols="4" sm="3" md="3" lg="3" offset="4" class="text-center" :class="{ 'pt-0': $vuetify.breakpoint.smAndDown }">
            <span class="title-content" :class="{'lg-view' : $vuetify.breakpoint.mdAndUp, 'sm-view': $vuetify.breakpoint.smOnly, 'xl-view': $vuetify.breakpoint.xlOnly, 'xs-view': $vuetify.breakpoint.xsOnly,}">Menús</span>

            <p class="description-content pt-sm-1 pt-md-3 pt-lg-4 px-md-4 px-lg-4" :class="{'lg-view' : $vuetify.breakpoint.mdAndUp, 'sm-view': $vuetify.breakpoint.smOnly, 'xl-view': $vuetify.breakpoint.xlOnly, 'xs-view': $vuetify.breakpoint.xsOnly,}">Forma menús desde los propios platos y los mismo asumirán la información de cada plato.</p>
          </v-col>
        </v-row>      
      </v-responsive>
      </v-container>
      </v-col>
    </v-row>
    <template v-if="$vuetify.breakpoint.xsOnly">
      <v-row class="mx-0">
        <v-col cols="12" class="pa-0">
          <v-img
            :src="require('@/assets/Home/madera.jpg')"
            class="mx-auto">
            <div class="white" style="width: 100%; z-index: -1; position: absolute; top: 0; left: 0;" :style="line"></div>
            <v-img
              :src="require('@/assets/Home/plato.png')"
              width="40%"
              height="auto"
              class="mx-auto"
            ></v-img> 
          </v-img>
        </v-col>
      </v-row>
        <v-container class="px-4" :class="{'mt-content': $vuetify.breakpoint.mdAndUp, 'py-12': $vuetify.breakpoint.mdAndUp, 'py-9': $vuetify.breakpoint.xsOnly, 'py-12': $vuetify.breakpoint.smOnly }">
          <v-responsive height="100%" width="100%">
            <v-row class="mx-0 mt-3">
              <v-col cols="12" class="text-center">

                <span class="title-content" :class="{'lg-view' : $vuetify.breakpoint.mdAndUp, 'sm-view': $vuetify.breakpoint.smOnly, 'xl-view': $vuetify.breakpoint.xlOnly, 'xs-view': $vuetify.breakpoint.xsOnly,}">Imágenes</span>

                <p class="description-content pt-3 pt-md-3 pt-lg-4" :class="{'lg-view' : $vuetify.breakpoint.mdAndUp, 'sm-view': $vuetify.breakpoint.smOnly, 'xl-view': $vuetify.breakpoint.xlOnly, 'xs-view': $vuetify.breakpoint.xsOnly,}">Capta la atención de los clientes de forma que le supongan una motivación para pedir.</p>
              </v-col>

              <v-col cols="12" class="text-center mb-5">
                <span class="title-content" :class="{'lg-view' : $vuetify.breakpoint.mdAndUp, 'sm-view': $vuetify.breakpoint.smOnly, 'xl-view': $vuetify.breakpoint.xlOnly, 'xs-view': $vuetify.breakpoint.xsOnly,}">Descripción</span>

                <p class="description-content pt-3 pt-md-3 pt-lg-4" :class="{'lg-view' : $vuetify.breakpoint.mdAndUp, 'sm-view': $vuetify.breakpoint.smOnly, 'xl-view': $vuetify.breakpoint.xlOnly, 'xs-view': $vuetify.breakpoint.xsOnly,}">Describe tu plato para dar más información.</p>
              </v-col>
            </v-row>  
            <v-row class="mx-0 mb-xl-12">
              <v-col cols="12" class="text-center mb-5" :class="{ 'pt-0': $vuetify.breakpoint.smAndDown }">
                <span class="title-content" :class="{'lg-view' : $vuetify.breakpoint.mdAndUp, 'sm-view': $vuetify.breakpoint.smOnly, 'xl-view': $vuetify.breakpoint.xlOnly, 'xs-view': $vuetify.breakpoint.xsOnly,}">Alérgenos</span>

                <p class="description-content pt-3 pt-md-3 pt-lg-4 px-md-6 px-lg-6" :class="{'lg-view' : $vuetify.breakpoint.mdAndUp, 'sm-view': $vuetify.breakpoint.smOnly, 'xl-view': $vuetify.breakpoint.xlOnly, 'xs-view': $vuetify.breakpoint.xsOnly,}">Pon los alérgenos de cada plato y tus clientes filtrarán los platos para ir a lo que puedan comer.</p>
              </v-col>
              <v-col cols="12" class="text-center mb-5" :class="{ 'pt-0': $vuetify.breakpoint.smAndDown }">
                <span class="title-content" :class="{'lg-view' : $vuetify.breakpoint.mdAndUp, 'sm-view': $vuetify.breakpoint.smOnly, 'xl-view': $vuetify.breakpoint.xlOnly, 'xs-view': $vuetify.breakpoint.xsOnly,}">Menús</span>

                <p class="description-content pt-3 pt-md-3 pt-lg-4 px-md-4 px-lg-4" :class="{'lg-view' : $vuetify.breakpoint.mdAndUp, 'sm-view': $vuetify.breakpoint.smOnly, 'xl-view': $vuetify.breakpoint.xlOnly, 'xs-view': $vuetify.breakpoint.xsOnly,}">Forma menús desde los propios platos y los mismo asumirán la información de cada plato.</p>
              </v-col>
            </v-row>      
          </v-responsive>
        </v-container>      
    </template>
  </section>
</template>
<script>
  export default{
    name: 'Content',
    computed:{
       Height () {
        let height = '';
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': height = '35vh'; break;
          case 'sm': height = '70vh'; break;
          case 'md': height = '83vh'; break;
          case 'lg': height = '93vh'; break;
          case 'xl': height = '83vh'; break;
        }

        return `calc(${height} - ${this.$vuetify.application.top}px)`
      },

      line(){
        return {
          height: this. $vuetify.breakpoint.xlOnly ? '100px' : this.$vuetify.breakpoint.mdAndUp ? '90px' : '35px'
        }
      }
    }
  }
</script>

<style lang="sass" scope>

  .title-content
    font-weight: 700
    line-height: 1.15em
    font-family: Museo-700 !important
    color: #2c2a2b !important
    &.lg-view 
      font-size: 2.5rem !important
    &.sm-view 
      font-size: 1.2rem !important
    &.xs-view 
      font-size: 1.7rem !important
    &.xl-view 
      font-size: 3.3125rem !important

  .description-content
    line-height: 1.6em
    font-weight: 100
    font-family: Roboto, sans-serif !important
    color: #2c2a2b !important
    &.lg-view 
      font-size: 1.225rem !important
    &.sm-view 
      font-size: 0.925rem !important
    &.xs-view 
      font-size: 1.225rem !important
    &.xl-view 
      font-size: 2.3rem !important

  .mt-content
    margin-top: 48px !important

</style>